exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-contact-us-contact-us-form-tsx": () => import("./../../../src/pages/contact-us/ContactUsForm.tsx" /* webpackChunkName: "component---src-pages-contact-us-contact-us-form-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-referrals-index-tsx": () => import("./../../../src/pages/referrals/index.tsx" /* webpackChunkName: "component---src-pages-referrals-index-tsx" */),
  "component---src-pages-referrals-referral-form-tsx": () => import("./../../../src/pages/referrals/ReferralForm.tsx" /* webpackChunkName: "component---src-pages-referrals-referral-form-tsx" */),
  "component---src-pages-sectors-engineering-tsx": () => import("./../../../src/pages/sectors/engineering.tsx" /* webpackChunkName: "component---src-pages-sectors-engineering-tsx" */),
  "component---src-pages-sectors-science-tsx": () => import("./../../../src/pages/sectors/science.tsx" /* webpackChunkName: "component---src-pages-sectors-science-tsx" */),
  "component---src-pages-sectors-software-tsx": () => import("./../../../src/pages/sectors/software.tsx" /* webpackChunkName: "component---src-pages-sectors-software-tsx" */),
  "component---src-pages-services-employee-retention-credit-tsx": () => import("./../../../src/pages/services/employee-retention-credit.tsx" /* webpackChunkName: "component---src-pages-services-employee-retention-credit-tsx" */),
  "component---src-pages-services-rd-tax-credits-california-rd-tax-credits-tsx": () => import("./../../../src/pages/services/rd-tax-credits/california-rd-tax-credits.tsx" /* webpackChunkName: "component---src-pages-services-rd-tax-credits-california-rd-tax-credits-tsx" */),
  "component---src-pages-services-rd-tax-credits-index-tsx": () => import("./../../../src/pages/services/rd-tax-credits/index.tsx" /* webpackChunkName: "component---src-pages-services-rd-tax-credits-index-tsx" */),
  "component---src-pages-services-rd-tax-credits-maryland-rd-tax-credit-tsx": () => import("./../../../src/pages/services/rd-tax-credits/maryland-rd-tax-credit.tsx" /* webpackChunkName: "component---src-pages-services-rd-tax-credits-maryland-rd-tax-credit-tsx" */),
  "component---src-pages-services-rd-tax-credits-rd-tax-credit-for-startups-tsx": () => import("./../../../src/pages/services/rd-tax-credits/rd-tax-credit-for-startups.tsx" /* webpackChunkName: "component---src-pages-services-rd-tax-credits-rd-tax-credit-for-startups-tsx" */),
  "component---src-pages-services-tax-incentives-tsx": () => import("./../../../src/pages/services/tax-incentives.tsx" /* webpackChunkName: "component---src-pages-services-tax-incentives-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-services-usa-employee-retention-credit-tsx": () => import("./../../../src/pages/services/usa/employee-retention-credit.tsx" /* webpackChunkName: "component---src-pages-services-usa-employee-retention-credit-tsx" */),
  "component---src-pages-services-usa-rd-tax-credits-california-rd-tax-credits-tsx": () => import("./../../../src/pages/services/usa/rd-tax-credits/california-rd-tax-credits.tsx" /* webpackChunkName: "component---src-pages-services-usa-rd-tax-credits-california-rd-tax-credits-tsx" */),
  "component---src-pages-services-usa-rd-tax-credits-index-tsx": () => import("./../../../src/pages/services/usa/rd-tax-credits/index.tsx" /* webpackChunkName: "component---src-pages-services-usa-rd-tax-credits-index-tsx" */),
  "component---src-pages-services-usa-rd-tax-credits-maryland-rd-tax-credit-tsx": () => import("./../../../src/pages/services/usa/rd-tax-credits/maryland-rd-tax-credit.tsx" /* webpackChunkName: "component---src-pages-services-usa-rd-tax-credits-maryland-rd-tax-credit-tsx" */),
  "component---src-pages-services-usa-rd-tax-credits-rd-tax-credit-for-startups-tsx": () => import("./../../../src/pages/services/usa/rd-tax-credits/rd-tax-credit-for-startups.tsx" /* webpackChunkName: "component---src-pages-services-usa-rd-tax-credits-rd-tax-credit-for-startups-tsx" */),
  "component---src-pages-services-usa-tax-incentives-tsx": () => import("./../../../src/pages/services/usa/tax-incentives.tsx" /* webpackChunkName: "component---src-pages-services-usa-tax-incentives-tsx" */),
  "component---src-templates-media-and-news-index-tsx": () => import("./../../../src/templates/media-and-news/index.tsx" /* webpackChunkName: "component---src-templates-media-and-news-index-tsx" */),
  "component---src-templates-read-post-tsx": () => import("./../../../src/templates/read-post.tsx" /* webpackChunkName: "component---src-templates-read-post-tsx" */)
}

